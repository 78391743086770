<template>
    <div id="employee-detail" class="page-content">

        <section-title :title="title"/>

        <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--6-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                <router-link class="mdl-navigation__link back-list" :to="{ name: 'EmployeeList' }">
                    <i class="material-icons">keyboard_arrow_left</i> {{ $t('shared.back') }}
                </router-link>
            </div>

            <div class="mdl-cell mdl-cell--6-col mdl-cell--8-col-tablet mdl-cell--4-col-phone wrapper-resend">
                <span class="mdl-chip mdl-chip--deletable">
                    <span class="mdl-chip__text">{{ $t('details-employee.activation') }}</span>
                    <button @click="onActivation" id="activation" type="button"
                            class="margin sweet-alert-button mdl-chip__action"><i
                            class="material-icons">send</i></button>
                </span>
            </div>
        </div>

        <div class="mdl-grid">
            <form @submit.prevent="onUpdate">
                <div class="mdl-grid" v-if="employee">

                    <div class="mdl-cell mdl-cell--3-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                        <div id="msg-profile" class="mdl-textfield mdl-js-textfield">
                            <div class="mask-edit">
                                <img :src="employee.profile" class="profile-foto-edit" id="profile-employee"
                                     alt="Profile image">
                            </div>
                            <label for="upload-profile" class="add-image-profile">
                                <span class="material-icons">cloud_upload</span>
                                <span class="upload-text">{{ $t('details-employee.change-profile') }}</span>
                            </label>
                            <input id="upload-profile" @change="onFileSelected" accept="image/*" type="file"
                                   name="files"/>
                            <span id="msg-profile-error" class="mdl-textfield__error">We haven't found your face's picture</span>
                            <span class="mdl-textfield__success">Photo OK!</span>
                        </div>
                    </div>

                    <div class="mdl-cell mdl-cell--3-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                        <div id="msg-code"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" maxlength="10"
                                   id="code-employee" v-model="employee.code" required>
                            <label class="mdl-textfield__label" for="code-employee">{{ $t('details-employee.id')
                                }}</label>
                            <span class="mdl-textfield__error">Employee id could be already registered</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-email"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="email" v-model="employee.email"
                                   id="email-employee" autocomplete="email"
                                   pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" required>
                            <label class="mdl-textfield__label" for="email-employee">{{ $t('details-employee.email')
                                }}</label>
                            <span class="mdl-textfield__error">Email could be already registered</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-name"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" v-model="employee.name"
                                   id="name-employee" required>
                            <label class="mdl-textfield__label" for="name-employee">{{ $t('details-employee.name')
                                }}</label>
                            <span class="mdl-textfield__error">Name is required</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-surname"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text"
                                   v-model="employee.surname" id="surname-employee" required>
                            <label class="mdl-textfield__label" for="surname-employee">{{ $t('details-employee.surname')
                                }}</label>
                            <span class="mdl-textfield__error">Surname is required</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                    </div>

                    <div class="mdl-cell mdl-cell--3-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">

                        <div id="msg-mobile"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" maxlength="15"
                                   v-model="employee.mobile" id="mobile-employee">
                            <label class="mdl-textfield__label" for="mobile-employee">{{ $t('details-employee.mobile')
                                }}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-position"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" maxlength="15"
                                   v-model="employee.position" id="position-employee">
                            <label class="mdl-textfield__label" for="position-employee">{{
                                $t('details-employee.position') }}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-workplace"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" maxlength="20"
                                   v-model="employee.workPlace" id="workplace-employee">
                            <label class="mdl-textfield__label" for="workplace-employee">{{
                                $t('details-employee.workplace') }}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-department"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" maxlength="20"
                                   v-model="employee.department" id="department-employee">
                            <label class="mdl-textfield__label" for="department-employee">{{
                                $t('details-employee.department') }}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                    </div>

                    <div class="mdl-cell mdl-cell--3-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">

                        <div id="msg-ssn" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" maxlength="12"
                                   v-model="employee.ssn" id="ssn-employee">
                            <label class="mdl-textfield__label" for="ssn-employee">{{ $t('details-employee.ssn')
                                }}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-birthday"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <datepicker v-model="employee.birthday"
                                        :language="pickerLang"
                                        placeholder=""
                                        clear-button
                                        name="birthday-employee">
                            </datepicker>
                            <label class="mdl-textfield__label">{{ $t('details-employee.birthday') }}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-created-at"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" v-model="employee.createdAt"
                                   id="created-at-employee" disabled>
                            <label class="mdl-textfield__label" for="created-at-employee">{{
                                $t('details-employee.created-at') }}</label>
                        </div>

                    </div>
                </div>

                <div class="mdl-grid">
                    <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                        <p id="msg-error" class="mdl-textfield__error">Error!</p>
                        <p id="msg-success" class="mdl-textfield__success">Employee updated!</p>
                    </div>
                    <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone wrapper-buttons-details">
                        <button type="button" @click="onDeleteModal" id="delete"
                                class="margin sweet-alert-button mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect">
                            {{ $t('details-employee.delete') }}
                        </button>
                        <button type="submit" id="submit"
                                class="mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect">
                            {{ $t('details-employee.save') }}
                        </button>
                    </div>
                </div>
                <vue-element-loading :active="requesting" spinner="bar-fade-scale" color="#FF6700"/>
            </form>
        </div>
    </div>
</template>

<style scope lang="scss">
    .wrapper-resend {
        display: flex;
        justify-content: flex-end;
    }
</style>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import SectionTitle from "@/components/shared/SectionTitle"
    import VueElementLoading from "vue-element-loading"
    import Datepicker from 'vuejs-datepicker'
    import {EMPTY_IMAGE_PROFILE} from "@/services/shared/Attendance"

    export default {
        name: "EmployeeDetails",
        props: ['employeeId'],
        created() {
            this.employee = Object.assign({}, this.getEmployeeById(this.employeeId))
            if (this.employee.id === undefined) {
                this.goToEmployeeList()
            }
            this.employee.createdAt = new Date(this.employee.createdAt).toLocaleString()
            if (this.employee.profile === '') {
                this.employee.profile = EMPTY_IMAGE_PROFILE
            }
        },
        data() {
            return {
                employee: [],
                profileChanged: false,
                title: this.$i18n.t('details-employee.title')
            }
        },
        computed: {
            ...mapState('employee', ['requesting']),
            ...mapGetters('employee', ['getEmployeeById']),
            ...mapGetters('config', ['pickerLang'])
        },
        methods: {
            ...mapActions('employee', ['updateEmployee', 'deleteEmployee', "sendActivation"]),
            onFileSelected(event) {
                let file = event.target.files[0];
                let img = document.getElementById('profile-employee')
                if (file) {
                    const reader = new FileReader()
                    reader.onload = (e) => img.src = e.target.result
                    reader.readAsDataURL(file)
                    this.profileChanged = true
                }
            },
            onDeleteModal() {
                this.$swal({
                    title: this.$i18n.t('details-employee.delete-modal-title'),
                    confirmButtonText: this.$i18n.t('details-employee.delete-modal'),
                    text: this.$i18n.t('details-employee.delete-modal-text'),
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        this.delete()
                    }
                })
            },
            async onUpdate() {
                try {
                    const employee = {
                        code: this.employee.code,
                        name: this.employee.name,
                        surname: this.employee.surname,
                        email: this.employee.email,
                        department: this.employee.department,
                        position: this.employee.position,
                        mobile: this.employee.mobile,
                        workPlace: this.employee.workPlace,
                        birthday: this.employee.birthday,
                        password: this.employee.password,
                        ssn: this.employee.ssn,
                        id: this.employee.id
                    }
                    if (this.profileChanged) {
                        employee.profile = document.getElementById('profile-employee').src
                    }
                    await this.updateEmployee(employee)
                    this.$toast.success(this.$i18n.t('details-employee.updated'))
                    await this.goToEmployeeList()
                    this.profileChanged = false
                } catch (error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            },
            async delete() {
                try {
                    await this.deleteEmployee(this.employeeId)
                    this.$toast.success(this.$i18n.t('details-employee.deleted'))
                    await this.goToEmployeeList()
                } catch (error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            },
            async goToEmployeeList() {
                await this.$router.push({name: 'EmployeeList'})
            },
            async onActivation() {
                try {
                    await this.sendActivation(this.employeeId)
                    this.$toast.success(this.$i18n.t('details-employee.activation-msg'))
                } catch (error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            }
        },
        components: {
            SectionTitle,
            VueElementLoading,
            Datepicker
        }
    }
</script>
